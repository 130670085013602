import Controller from './Controller'

export default class ArticleController extends Controller {
  /**
   * Find an article.
   */
  async show ({ to, from }) {
    const slug = to.params.slug

    return this.$store.dispatch('article/loadCurrentArticle', slug)
      .catch(() => {
        this.$messages.danger('The requested article was not found.')

        return '/'
      })
  }
}
