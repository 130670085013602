import Vue from 'vue'
import Router from 'vue-router'

import Locator from '@/core/services/Locator'
import config from '@/config'

import AuthController from './controllers/AuthController'
import BlogController from './controllers/BlogController'
import PageController from './controllers/PageController'
import DealsController from './controllers/DealsController'
import ArticleController from './controllers/ArticleController'

import ApplySeo from './middleware/ApplySeo'
import LoadUser from './middleware/LoadUser'
import Localize from './middleware/Localize'
import NavigateTo from './middleware/NavigateTo'
import RefreshOnce from './middleware/RefreshOnce'
import WaitForHeap from './middleware/WaitForHeap'
import OfflineCheck from './middleware/OfflineCheck'
import Authenticated from './middleware/Authenticated'
import ScanUtmParams from './middleware/ScanUtmParams'
import ScrollToPlace from './middleware/ScrollToPlace'
import RemoveShownMessages from './middleware/RemoveShownMessages'
import NavigateToInstallation from './middleware/NavigateToInstallation'
import PopulateInfluencerUtms from './middleware/PopulateInfluencerUtms'
import RedirectToTrackingLink from './middleware/RedirectToTrackingLink'
import NavigateAuthenticatedTo from './middleware/NavigateAuthenticatedTo'

Vue.use(Router)

export default new Router({

  /**
   * Basic Vue Router options
   */

  mode: 'history',
  linkActiveClass: 'is-active',

  /*
   * Registering global middleware interceptors
   */
  globalMiddleware: [
    ApplySeo,
    LoadUser,
    Localize,
    OfflineCheck,
    ScanUtmParams,
    ScrollToPlace,
    RemoveShownMessages
  ],

  routes: [
    {
      path: Locator.localizedRoot(),
      component: { render: h => h('router-view') },
      children: [
        {
          path: 'ouch',
          beforeEnter () {
            window.location.href = config.ouchDealsUrl
          }
        },
        /*
         * Generic page routes
         */
        {
          path: '',
          name: 'home',
          component: () => import('./views/Home/Index'),
          meta: {
            controller: [PageController, 'home']
          }
        },
        {
          path: 'messenger',
          name: 'bot',
          component: () => import('./views/FacebookMessengerBot')
        },
        {
          path: 'faq',
          name: 'faq',
          component: () => import('./views/Faq'),
          meta: {
            controller: [PageController, 'faq']
          },
          children: [
            {
              path: ':category',
              name: 'faq-category',
              component: () => import('./views/Faq'),
              meta: {
                controller: [PageController, 'faq']
              },
              children: [
                {
                  path: ':question',
                  name: 'faq-question',
                  component: () => import('./views/Faq'),
                  meta: {
                    controller: [PageController, 'faq']
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'contact',
          name: 'contact',
          component: () => import('./views/Contact')
        },
        {
          path: 'cms',
          name: 'cms',
          component: () => import('./views/Cms')
        },
        {
          path: 'privacy-policy',
          name: 'privacypolicy',
          component: () => import('./views/PrivacyPolicy')
        },
        {
          path: 'terms-and-conditions',
          name: 'tandcs',
          component: () => import('./views/TermsAndConditions'),
          meta: {
            controller: [PageController, 'tos']
          },
          children: [
            {
              path: ':category',
              name: 'tandcs-category',
              component: () => import('./views/Faq'),
              meta: {
                controller: [PageController, 'tos']
              },
              children: [
                {
                  path: ':question',
                  name: 'faq-question',
                  component: () => import('./views/Faq'),
                  meta: {
                    controller: [PageController, 'tos']
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'cookie-policy',
          name: 'cookiepolicy',
          component: () => import('./views/CookiePolicy')
        },
        {
          path: 'imprint',
          name: 'imprint',
          component: () => import('./views/Imprint')
        },
        {
          path: 'retailer-benefits',
          name: 'retailerbenefits',
          component: () => import('./views/RetailerBenefits'),
          meta: {
            controller: [PageController, 'benefits']
          }
        },
        {
          path: 'points',
          name: 'points',
          component: () => import('./views/PouchPointsLanding'),
          meta: {}
        },
        {
          path: 'press',
          name: 'press',
          component: () => import('./views/Press'),
          meta: {
            controller: [PageController, 'press']
          }
        },
        {
          path: 'uninstall',
          name: 'uninstall',
          component: () => import('./views/Uninstall')
        },
        {
          path: 'unsupported-browser',
          name: 'unsupportedbrowser',
          component: () => import('./views/UnsupportedBrowser')
        },
        {
          path: 'desktop-only',
          name: 'desktoponly',
          component: () => import('./views/DesktopOnly')
        },
        {
          path: 'dragonsden',
          name: 'dragonsden',
          component: () => import('./views/Dragonsden')
        },

        /*
         * Dynamic landing page routes
         */
        {
          path: 'landing/:retailer/:country_iso?',
          name: 'customretailer',
          component: () => import('./views/campaigns/CustomLandingPage/InfluencerLandingPage'),
          meta: {
            controller: [PageController, 'crlp']
          }
        },

        /*
         * Promo article route
         */
        {
          path: 'articles/:slug',
          name: 'article',
          component: () => import('./views/Article'),
          meta: {
            controller: [ArticleController, 'show']
          }
        },

        /*
         * Blog page routes
         */
        {
          path: 'blog',
          name: 'blog',
          component: () => import('./views/blog/Index'),
          meta: {
            controller: [BlogController, 'index']
          }
        },
        {
          path: 'blog/:slug',
          name: 'post',
          component: () => import('./views/blog/Show'),
          meta: {
            controller: [BlogController, 'show']
          }
        },

        /*
         * Content routes; Deals, Merchants...
         */
        {
          // Authenticated Route...
          path: 'deals',
          name: 'deals',
          component: () => import('./views/deals/Index'),
          meta: {
            middleware: [Authenticated],
            controller: [DealsController, 'index']
          }
        },
        {
          // Authenticated Route...
          path: 'deals/:merchant',
          name: 'merchant',
          component: () => import('./views/deals/Merchant'),
          meta: {
            middleware: [Authenticated],
            controller: [DealsController, 'merchant']
          }
        },
        {
          // Authenticated Route...
          path: 'retailers',
          name: 'merchants',
          component: () => import('./views/deals/Merchants'),
          meta: {
            middleware: [Authenticated],
            controller: [DealsController, 'merchants']
          }
        },
        {
          path: 'amazon-deal-finder',
          name: 'amazondealfinder',
          redirect: { name: 'home' }
        },

        /*
         * Authentication routes
         */
        {
          /**
           * User signup routes
           */
          path: 'join',
          component: () => import('./views/auth/Index'),
          children: [
            {
              path: '',
              name: 'join',
              component: () => import('./views/auth/EmailJoin'),
              meta: {
                middleware: [NavigateAuthenticatedTo('/account')],
                controller: [PageController, 'tos']
              }
            },
            {
              path: 'email',
              name: 'email-join',
              component: () => import('./views/auth/EmailJoin'),
              meta: {
                middleware: [NavigateAuthenticatedTo('/account')],
                controller: [PageController, 'tos']
              }
            },
            {
              path: 'success',
              name: 'join-complete',
              component: () => import('./views/auth/JoinSuccess'),
              meta: {
                middleware: [NavigateAuthenticatedTo('/account')]
              }
            }
          ]
        },
        {
          /**
           * User login routes
           */
          path: 'login',
          component: () => import('./views/auth/Index'),
          children: [
            {
              path: '',
              name: 'login',
              component: () => import('./views/auth/EmailLogin'),
              meta: {
                middleware: [NavigateAuthenticatedTo('/account')]
              }
            },
            {
              path: 'email',
              name: 'email-login',
              component: () => import('./views/auth/EmailLogin'),
              meta: {
                middleware: [NavigateAuthenticatedTo('/account')]
              }
            },
            {
              path: 'oauth2',
              name: 'oauth-confirm',
              meta: {
                controller: [AuthController, 'oauth']
              }
            }
          ]
        },
        {
          path: 'delete',
          name: 'delete',
          meta: {
            controller: [AuthController, 'delete']
          }
        },
        {
          /**
           * Password management routes
           */
          path: 'pw',
          component: () => import('./views/auth/Index'),
          children: [
            {
              path: 'forgot-password',
              name: 'forgot-password',
              component: () => import('./views/auth/PasswordForgot')
            },
            {
              path: 'reset/:email',
              name: 'reset-password',
              component: () => import('./views/auth/PasswordReset'),
              props: true
            }
          ]
        },
        /*
         * User account routes
         */
        {
          path: 'account',
          name: 'account',
          beforeEnter: (to) => window.location.href = `${process.env.VUE_APP_ACCOUNT_URL}${to.fullPath}`,
          component: () => import('./views/account/Index'),
          meta: {
            middleware: [NavigateTo('/pouch-points/overview')]
          },
          children: [
            {
              path: 'pouch-points',
              name: 'pouch-points',
              redirect: 'pouch-points/overview',
              children: [
                {
                  path: 'overview'
                },
                {
                  path: 'mygift-cards'
                }
              ]
            },
            {
              path: 'pouch-points'
            },
            {
              path: 'profile'
            },
            {
              path: 'notifications'
            }
          ]
        },

        /**
         * Onboarding route
         */
        {
          path: 'onboarding',
          name: 'onboarding',
          component: () => import('./views/onboarding/Index'),
          meta: {
            controller: [PageController, 'onboarding']
          }
        },

        /**
         * Marketing Campaigns
         */
        {
          path: 'gener8competition',
          name: 'gener8competition',
          component: () => import('./views/campaigns/Gener8/Index')
        },
        {
          path: 'cilp/:influencer?',
          name: 'cilp',
          component: () => import('./views/campaigns/CustomLandingPage/InfluencerLandingPage'),
          meta: {
            controller: [PageController, 'cilp']
          }
        },

        /*
         * Tracking link redirects.
         */
        {
          path: 'out',
          name: 'out',
          component: () => import('./views/Out'),
          meta: {
            middleware: [
              RedirectToTrackingLink
            ]
          }
        },

        /*
         * Permanent redirects
         */
        {
          path: 'i/:influencer?',
          component: {},
          meta: {
            middleware: [
              PopulateInfluencerUtms
            ]
          }
        },

        /*
         * Podcast link
         */
        {
          path: 'podcast/:influencer?',
          component: {},
          meta: {
            middleware: [
              PopulateInfluencerUtms
            ]
          }
        },

        {
          path: 'confirmation',
          component: {},
          meta: {
            middleware: [
              NavigateTo('/onboarding')
            ]
          }
        },
        {
          path: 'index.html',
          component: {},
          meta: {
            middleware: [
              NavigateTo('/')
            ]
          }
        },
        {
          path: 'install',
          name: 'install',
          component: {},
          meta: {
            middleware: [
              WaitForHeap,
              NavigateToInstallation
            ]
          }
        },

        /*
         * Error routes
         */
        {
          path: '*',
          name: '404',
          component: () => import('./views/errors/FourOhFour'),
          meta: {
            middleware: [
              RefreshOnce
            ]
          }
        }

      ]
    },
    {
      path: '*',
      redirect: to => Locator.locale + Locator.stripLocale(to.path)
    }
  ]
})
