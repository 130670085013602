<template>
  <footer class="footer is-white">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-2" v-only-locales="['en-gb']">
          <p class="has-text-weight-semibold" v-t="`partials.footer.features`"></p>

          <router-link :to="{ name: 'blog' }" v-t="`partials.footer.blog`"></router-link>
          <!-- <router-link :to="{ name: 'merchants' }" v-t="`partials.footer.retailers`"></router-link> -->
          <!-- <router-link :to="{ name: 'amazondealfinder' }" v-t="`features.amazon-deal-finder`"></router-link> -->
          <router-link :to="{ name: 'bot' }" v-t="`features.messenger-bot`"></router-link>
        </div>

        <div class="column is-2">
          <p class="has-text-weight-semibold" v-t="`partials.footer.about`"></p>

          <router-link :to="{ name: 'retailerbenefits' }" v-t="`partials.footer.retailer-benefits`"></router-link>
          <router-link :to="{
            path: 'contact',
            redirect: to => {
              return { path: zenDeskURL }
            }}" target="_blank" v-t="`partials.footer.faq`"></router-link>
          <router-link :to="{ name: 'press' }" v-t="`partials.footer.press`"></router-link>
          <router-link :to="{
            path: 'contact',
            redirect: to => {
              return { path: zenDeskURL }
            }}" target="_blank" v-t="`links.contact-us`">
          </router-link>
        </div>

        <div class="column is-2">
          <p class="has-text-weight-semibold" v-t="`partials.footer.legal`"></p>

          <router-link :to="{ name: 'imprint' }" target="_blank" v-t="`partials.footer.imprint`"></router-link>
          <router-link :to="{ name: 'tandcs' }" target="_blank" v-t="`links.terms-and-conditions`"></router-link>
          <router-link :to="{ name: 'privacypolicy' }" target="_blank" v-t="`links.privacy-policy`"></router-link>
          <router-link :to="{ name: 'cookiepolicy' }" target="_blank" v-t="`links.cookie-policy`"></router-link>
        </div>

        <div class="column is-1">
          <p class="has-text-weight-semibold" v-t="`partials.footer.language`"></p>

          <a class="picker" :href="setLocale('en-gb')">
            <span class="picker-tag" v-t="`partials.footer.locale-picker.en`"></span>
          </a>
          <a class="picker" :href="setLocale('de-de')">
            <span class="picker-tag" v-t="`partials.footer.locale-picker.de`"></span>
          </a>
        </div>

        <div class="column is-2 has-text-centered">
          <a target="_blank" ref="noopener" :href="$config.social.pages.facebook">
            <span class="icon is-large"><i class="fab fa-facebook fa-2x has-text-facebook"></i></span>
          </a>
          <a target="_blank" ref="noopener" :href="$config.social.pages.twitter">
            <span class="icon is-large"><i class="fab fa-twitter fa-2x has-text-twitter"></i></span>
          </a>
          <a target="_blank" ref="noopener" :href="$config.social.pages.instagram">
            <span class="icon is-large"><i class="fab fa-instagram fa-2x has-text-instagram"></i></span>
          </a>
        </div>
      </div>

      <hr class="is-light">
      <div class="has-padding-3 has-text-centered has-text-grey-light is-size-7">
        &copy; <span>{{ $t('partials.footer.copyright', { year }) }} </span>
      </div>
    </div>
  </footer>
</template>

<script>
import Locator from '@/core/services/Locator'
import config from '@/config'

export default {
  data () {
    return {
      zenDeskURL: config.zenDeskHelp
    }
  },
  computed: {
    year () {
      return new Date().getFullYear()
    }
  },

  methods: {
    setLocale (locale) {
      return '/' + locale + Locator.stripLocale(this.$route.path)
    }
  }
}
</script>
