import Controller from './Controller'

export default class BlogController extends Controller {
  /**
   * Load all blog posts available.
   */
  async index ({ to, from }) {
    return this.$store.dispatch('blog/loadPosts')
  }

  /**
   * Find a specific blog post.
   */
  async show ({ to, from }) {
    const slug = to.params.slug

    return this.$store.dispatch('blog/loadCurrentPost', slug)
      .catch(() => {
        this.$messages.danger('The blog post you requested was not found.')

        return '/blog'
      })
  }
}
