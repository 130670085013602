import Vue from 'vue'

/**
 * Env helper.
 *
 * @param {string} key
 * @param {any} fallback
 * @return {any}
 */
export const env = (key, fallback = undefined) => {
  const value = process.env[`VUE_APP_${key}`]

  if (value === undefined) {
    return fallback
  }

  switch (value.toLowerCase()) {
    case 'true':
    case '(true)':
      return true
    case 'false':
    case '(false)':
      return false
    case 'empty':
    case '(empty)':
      return ''
    case 'null':
    case '(null)':
      return
  }

  return value
}

/**
 * Copies provided text to clipboard.
 *
 * @param {string} text
 */
window.copyToClipboard = (text) => {
  const range = document.createRange()
  const element = document.getElementById('copy-to-clipboard')
  const selection = window.getSelection()

  element.value = text
  element.contenteditable = true
  element.readonly = false
  element.select()
  range.selectNodeContents(element)

  selection.removeAllRanges()
  selection.addRange(range)

  element.setSelectionRange(0, 100)

  document.execCommand('copy')
}

window.crossDeviceSupport = {
  /**
   * Whether current browser supports Pouch extension.
   *
   * @var {Boolean} crossDeviceSupport.supportedBrowser
   */
  supportedBrowser: _ => Vue.prototype.$browser.name.match(/(chrome|edge-chromium|firefox)/i),

  /**
   * Whether curent device has a mobile operation system.
   *
   * @var {Boolean} crossDeviceSupport.mobileOs
   */
  mobileOs: _ => Vue.prototype.$browser.os.match(/(android|ios)/i)
}
