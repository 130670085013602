<template>
  <p-button
    primary bold :small="small" :medium="medium" :large="large"
    @click="redirectToStore()"
    v-only-extension-fit
    v-hidden-with-extension
    v-hidden-with-onboarding
  >
    <slot></slot>
  </p-button>
</template>

<script>
import config from '@/config'
import { mapActions } from 'vuex'
import Uid from '@/core/services/Uid'
import Utm from '@/core/services/Utm'
import { ga } from '@/core/services/Analytics'
import Cookie from '@/core/services/Cookie'

export default {
  props: {
    small: Boolean,
    medium: Boolean,
    large: Boolean,
    position: String,
    enableInstallPopup: {
      default: true,
      type: Boolean
    }
  },

  methods: {
    ...mapActions(['toggleInstallPopup']),

    sendInstallEvent () {
      // Creates custom GA tracker object for install and dispatches event
      ga.create(config.analytics.tokens.ga, 'addToBrowserClicked')
      ga.name('addToBrowserClicked')
        .event('Install Button Tracker', 'userAction:addToBrowserClicked', this.position ?? 'n/a')
    },

    redirectToStore () {
      // Fire an event.
      this.$dispatcher.fire('app.addToBrowserClicked', { route: this.$route })

      // Convert `$browser.name` to corresponding config webstore key
      const storeKey = name => name === 'edge-chromium' ? 'edge' : name

      const url = Utm.addQuery(new URL(config.extension.installationLinks[storeKey(this.$browser.name)]))
      const ch = Cookie.cookieBox().customerHash
      url.searchParams.set('chid', ch || Uid.getOrNew())

      // Redirect to the store.
      if (this.$browser.name === 'chrome') {
        // Not alaways will need to toggle the install popup. Default value set to true.
        this.toggleInstallPopup(this.enableInstallPopup)
        window.open(
          url.toString(),
          'Install Pouch',
          `width=${Math.round(window.screen.width / 12 * 7)}, height=${window.screen.height - 100}, top=-1000000, left=-1000000`
        ).focus()
      } else {
        window.open(url.toString(), '_blank').focus()
      }

      this.sendInstallEvent()
      // Bubble the click event up.
      this.$emit('click')
    }
  }
}
</script>
